import React, { useEffect } from 'react';
import styles from './PhrasesOverlay.module.scss';

const calculateRelativeCoordinates = (originalCoordinates, image) => {
  const { originalSize, displayedSize } = image;

  if (!originalSize || !displayedSize) {
    return null;
  }

  const widthScale = displayedSize.width / originalSize.width;
  const heightScale = displayedSize.height / originalSize.height;

  const newVertices = originalCoordinates.vertices.map((vertex) => ({
    x: vertex.x * widthScale,
    y: vertex.y * heightScale,
  }));

  const minX = Math.min(...newVertices.map((vertex) => vertex.x));
  const maxX = Math.max(...newVertices.map((vertex) => vertex.x));
  const minY = Math.min(...newVertices.map((vertex) => vertex.y));
  const maxY = Math.max(...newVertices.map((vertex) => vertex.y));

  const width = maxX - minX;
  const height = maxY - minY;

  return {
    x: minX,
    y: minY,
    width: width,
    height: height,
  };
};



const PhrasesOverlay = ({ phrases, page, handlePhraseClick }) => {

  // useEffect(() => {
  //   console.log("A")
  // }, [page]);

  if (!phrases || !page.displayedSize) return null;

  
  const selectPhrase = (phrase) => () => {
    handlePhraseClick(phrase);
  };

  
  return (
    <div className={styles['phrases-overlay']}>
      {phrases.map((phrase, index) => {
        const relativePhrase = calculateRelativeCoordinates(phrase, page);
        return (
          relativePhrase && (
            <div
              key={index}
              className={`${styles.phrase} ${
                phrase === page.selectedPhrase ? styles.selected : ''
              }`}
              style={{
                left: `${relativePhrase.x}px`,
                top: `${relativePhrase.y}px`,
                width: `${relativePhrase.width}px`,
                height: `${relativePhrase.height}px`,
              }}
              onClick={selectPhrase(phrase)}
            >
              {phrase.text}
            </div>
          )
        );
      })}
    </div>
  );
  

};


export default PhrasesOverlay;