// scanUtils.js
import { scanPageLambda } from "../api/scan_images";
import { getUserData } from "../services/dynamoDBService";

export const handleScanPage = async (s3_image_key, setIsScanning, setLoading, setError, setJsonData, userCredits, setUserCredits) => {
  setIsScanning(true);
  setLoading(true);
  setError('');
  try {
    // const s3_image_key = page.image_url; // Ensure this is correctly defined
    const response = await scanPageLambda({ s3_image_key });
    const data = response;
    setJsonData(JSON.parse(data.body.pageData)); 
    if (Number(data.body.credits) < userCredits) {
      setUserCredits(data.body.credits); 
    }
  } catch (error) {
    console.error('Failed to call API:', error);
    setError('ERROR_LOADING_DATA');
  }
  setLoading(false);
  setIsScanning(false);
};

export const scanAllPages = async (pages, setCredits) => {
    console.log("scanAllPages", pages)
    const unprocessedPages = pages.filter(page => !page.data_url);
    const requiredCredits = unprocessedPages.length; // Assuming each page costs 5 credits to scan
    console.log("requiredCredits", requiredCredits)
    try {
      const userData = await getUserData();
      if (userData && userData.credits) {
        setCredits(userData.credits);
      }
      console.log("UserCredits", userData.credits)
      if (userData.credits >= requiredCredits) {
        // Proceed with scanning
        console.log('Scanning all pages');
        // Dummy function to simulate scanning
        for (const page of unprocessedPages) {
            // Your scanning logic here
            console.log(`Scanning ${page.image_url}`);
            const s3_image_key = page.image_url;
            const response = await scanPageLambda({ s3_image_key });
            const data = response;
            setCredits(data.body.credits);  // Update the user credits state
        }
      } else {
        alert('Not enough credits');
    }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };