import React, { useRef, useState, useEffect } from 'react';
import styles from './SideBar.module.scss';
import { useTranslation } from 'react-i18next';
import { initDB, getKanjiByLiteral } from './../../utils/kanjiDatabase';
import { FaTimes } from 'react-icons/fa';

function SideBar({
  selectedPhrase,
  selectedWord,
  handleWordClick,
  onClose,
  isOpen,
}) {
  const { t } = useTranslation();
  const sidebarRef = useRef(null);
  const [kanjiDatabaseData, setKanjiDatabaseData] = useState({});
  const [kanjiCharacters, setKanjiCharacters] = useState([]);

  // Fetch kanji data when selectedWord changes
  useEffect(() => {
    let isMounted = true; // Flag to prevent state updates after unmounting

    async function fetchKanjiData() {
      if (selectedWord && selectedWord.original_text) {
        // Extract kanji characters from the original_text
        
        const kanjiChars = extractKanjiCharacters(selectedWord.original_text);
        console.log("Selected Word", selectedWord, kanjiChars)
        setKanjiCharacters(kanjiChars);

        if (kanjiChars.length > 0) {
          const db = await initDB();

          const kanjiDataPromises = kanjiChars.map(async (kanjiChar) => {
            const data = await getKanjiByLiteral(db, kanjiChar);
            return { kanjiChar, data };
          });

          const kanjiDataArray = await Promise.all(kanjiDataPromises);

          const kanjiDataMap = {};
          kanjiDataArray.forEach(({ kanjiChar, data }) => {
            kanjiDataMap[kanjiChar] = data;
          });

          if (isMounted) {
            setKanjiDatabaseData(kanjiDataMap);
          }
        } else if (isMounted) {
          setKanjiDatabaseData({});
        }
      } else if (isMounted) {
        setKanjiCharacters([]);
        setKanjiDatabaseData({});
      }
    }

    fetchKanjiData();

    return () => {
      isMounted = false; // Clean up flag on unmount
    };
  }, [selectedWord]);

  const handleCloseClick = (e) => {
    if (sidebarRef.current && !sidebarRef.current.contains(e.target)) {
      onClose(); // Close the modal if click is outside the sidebar
    }
  };

  // Function to map grammar classification to CSS class names
  function getGrammarClass(grammarClassification) {
    if (!grammarClassification) return styles.defaultWord;

    const classification = grammarClassification.toLowerCase();

    if (classification.includes('adverb')) {
      return styles.adverb;
    } else if (classification.includes('verb')) {
      return styles.verb;
    } else if (classification.includes('adjective')) {
      return styles.adjective;
    } else if (classification.includes('noun')) {
      return styles.noun;
    } else if (classification.includes('particle')) {
      return styles.particle;
    } else {
      return styles.defaultWord;
    }
  }

  // Function to extract kanji characters from a string
  function extractKanjiCharacters(text) {
    // Kanji Unicode range: \u4e00 - \u9faf
    const kanjiRegex = /[\u4e00-\u9faf]/g;
    const kanjiChars = text.match(kanjiRegex) || [];
    // Remove duplicates
    return Array.from(new Set(kanjiChars));
  }

  if (!isOpen) return null;

  return (
    <div className={styles.sidebarOverlay} onClick={handleCloseClick}>
      <div className={styles.sidebar} ref={sidebarRef}>
        <button onClick={onClose} className={styles.closeBtn}>
          <FaTimes />
        </button>
        {selectedPhrase && (
          <>
            <div className={styles.section}>
              <h2 className={styles.heading}>{t('ORIGINAL_TEXT')}</h2>
              <div className={styles.phraseContainer}>
                {selectedPhrase.words.map((word, index) => {
                  const grammarClass = getGrammarClass(
                    word.grammar_classification
                  );
                  return (
                    <button
                      key={index}
                      onClick={() => handleWordClick(word)}
                      className={`${styles.wordButton} ${grammarClass}`}
                    >
                      {word.original_text}
                    </button>
                  );
                })}
              </div>
            </div>
            <div className={styles.section}>
              <h2 className={styles.heading}>{t('TRANSLATION')}</h2>
              <p className={styles.translatedText}>
                {selectedPhrase.translated_text}
              </p>
            </div>
            {selectedWord && (
              <div className={styles.wordDetails}>
                <div className={styles.section}>
                  <h2 className={styles.subheading}>{t('WORD_DETAILS')}</h2>
                  <div className={styles.detailItem}>
                    <strong>{t('ORIGINAL_TEXT')}:</strong>{' '}
                    {selectedWord.original_text}
                  </div>
                  <div className={styles.detailItem}>
                    <strong>{t('HIRAGANA')}:</strong> {selectedWord.hiragana}
                  </div>
                  <div className={styles.detailItem}>
                    <strong>{t('TRANSLATED_TEXT')}:</strong>{' '}
                    {selectedWord.translated_text}
                  </div>
                  <div className={styles.detailItem}>
                    <strong>{t('MEANING')}:</strong> {selectedWord.meaning}
                  </div>
                  <div className={styles.detailItem}>
                    <strong>{t('GRAMMAR_CLASSIFICATION')}:</strong>{' '}
                    {selectedWord.grammar_classification}
                  </div>
                </div>
                {kanjiCharacters.length > 0 && (
                  <div className={styles.section}>
                    <h2 className={styles.subheading}>{t('KANJI_DETAILS')}</h2>
                    <ul className={styles.kanjiList}>
                      {kanjiCharacters.map((kanjiChar, index) => {
                        const dbData = kanjiDatabaseData[kanjiChar];
                        return (
                          <li key={index} className={styles.kanjiDetails}>
                            <div className={styles.kanjiCharacter}>
                              {kanjiChar}
                            </div>
                            <div className={styles.kanjiInfo}>
                              {dbData ? (
                                <>
                                  <div className={styles.detailItem}>
                                    <strong>{t('MEANINGS')}:</strong>{' '}
                                    {dbData.meanings &&
                                      dbData.meanings.join(', ')}
                                  </div>
                                  <div className={styles.detailItem}>
                                    <strong>{t('READINGS')}:</strong>{' '}
                                    {dbData.readings &&
                                      dbData.readings
                                        .map(
                                          (r) =>
                                            `${
                                              r.type === 'ja_on'
                                                ? t("On'yomi")
                                                : t("Kun'yomi")
                                            }: ${r.reading}`
                                        )
                                        .join(', ')}
                                  </div>
                                  <div className={styles.detailItem}>
                                    <strong>{t('STROKE_COUNT')}:</strong>{' '}
                                    {dbData.stroke_count}
                                  </div>
                                  {/* Add more database fields as needed */}
                                </>
                              ) : (
                                <div className={styles.detailItem}>
                                  {t('KANJI_DATA_NOT_FOUND')}
                                </div>
                              )}
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default SideBar;
