const cognitoConfig = {
  ClientId: '6t71b5h3on8k0sk6os8r2jj21', // Replace with your App Client ID
  AppWebDomain: 'manga-babel.auth.eu-west-2.amazoncognito.com', // Replace with your Cognito domain
  TokenScopesArray: ['email', 'openid', 'profile'],
  RedirectUriSignIn: process.env.REACT_APP_REDIRECT_URI_SIGN_IN, // Dynamically set based on environment
  RedirectUriSignOut: process.env.REACT_APP_REDIRECT_URI_SIGN_OUT,
  IdentityProvider: 'Google',
  UserPoolId: 'eu-west-2_GlYm8QaQx', // Replace with your User Pool ID
  AdvancedSecurityDataCollectionFlag: true,
  ResponseType: 'code', // This tells Cognito to use Authorization Code Grant

};

export default cognitoConfig;
