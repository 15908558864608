import React, { useState, useEffect } from 'react';
import { getSessionToken } from '../../services/cognitoService';

export function ProtectedImage({ src, className, style }) {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    let isMounted = true;
    const fetchImage = async () => {
      const token = await getSessionToken();
      if (src && token && isMounted) {
        try {
          const response = await fetch(src, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              Origin: 'http://localhost:3000'
            }
          });

          if (!response.ok) throw new Error('Network response was not ok');
          const blob = await response.blob();
          const url = URL.createObjectURL(blob);
          
          if (isMounted) {
            setImageUrl(url);
          }

          return () => {
            URL.revokeObjectURL(url);
            isMounted = false;
          };
        } catch (error) {
          console.error('Error fetching protected image:', error);
        }
      }
    };

    fetchImage();

    return () => {
      isMounted = false;
    };
  }, [src]);

  return imageUrl ? (
    <img src={imageUrl} alt="Protected Content" className={className} style={style} />
  ) : (
    <p>Loading...</p>
  );
}
