// src/hooks/useFetchPages.js
import { useState, useEffect } from 'react';
import { getFilesInFolderPaginated } from '../services/dynamoDBService';
import { getSessionToken } from '../services/cognitoService';

export function useFetchPages(folderName, setPages, setLoadingState) {
  const [nextToken, setNextToken] = useState(null);
  const [initialLoadDone, setInitialLoadDone] = useState(false);

  const fetchPages = async (token = null, direction = 'next', limit = 10) => {
    const sessionToken = await getSessionToken();
    if (!sessionToken) {
      console.error('No token available for authentication.');
      return;
    }

    setLoadingState({ loading: true });
    try {
      const { files, nextKey } = await getFilesInFolderPaginated(folderName, token, limit);
      setPages(prevPages => [...prevPages, ...files]);
      setNextToken(nextKey);
    } catch (error) {
      console.error('Error fetching items:', error);
    } finally {
      setLoadingState({ loading: false });
    }
  };

  // Automatically fetch initial pages with adjusted limit
  useEffect(() => {
    const initialLoad = async () => {
      const savedPageIndexStr = localStorage.getItem(`currentPage_${folderName}`);
      const savedPageIndex = savedPageIndexStr !== null ? parseInt(savedPageIndexStr, 10) : 0;
      const initialLimit = savedPageIndex + 1; // Load pages up to the saved index

      await fetchPages(null, 'next', initialLimit);
      setInitialLoadDone(true);
    };

    if (!initialLoadDone) {
      initialLoad();
    }
  }, [folderName]);

  return { fetchPages, nextToken };
}
