// src/utils/indexedDB.js
import { openDB } from 'idb';

const DATABASE_NAME = 'UserPreferencesDB';
const DATABASE_VERSION = 1;
const STORE_NAME = 'UserPreferences';

// Open or create database
async function initDB() {
    const db = await openDB(DATABASE_NAME, DATABASE_VERSION, {
        upgrade(db) {
            // Create a store of objects
            if (!db.objectStoreNames.contains(STORE_NAME)) {
                db.createObjectStore(STORE_NAME, { keyPath: 'id' });
            }
        },
    });
    return db;
}

// Save user preferences
async function saveUserPreferences(user) {
    const db = await initDB();
    const tx = db.transaction(STORE_NAME, 'readwrite');
    await tx.store.put({ id: 'userPrefs', ...user });
    await tx.done;
}

// Get user preferences
async function getUserPreferences() {
    const db = await initDB();
    return db.get(STORE_NAME, 'userPrefs');
}

export { saveUserPreferences, getUserPreferences };
