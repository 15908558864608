import React, { useEffect } from 'react';
import { CognitoAuth } from 'amazon-cognito-auth-js';
import cognitoConfig from '../../config/cognitoConfig';
import styles from './GoogleSignIn.module.scss'; // SCSS module for styling
import { useTranslation } from 'react-i18next'; // Import the hook

const GoogleSignIn = () => {
  const { t } = useTranslation(); // Initialize the translation hook
  const auth = new CognitoAuth(cognitoConfig);
  auth.useCodeGrantFlow()

  useEffect(() => {
  
    auth.userhandler = {
      onSuccess: (result) => {
        localStorage.setItem('authToken', result.idToken.jwtToken);
        window.location.href = '/'; // Redirect to folders page after sign-in
      },
      onFailure: (err) => {
        console.error('Sign in failure:', err);
      }
    };
    auth.parseCognitoWebResponse(window.location.href);
  }, [auth]);

  const handleSignIn = () => {
    auth.getSession();
  };

return (
  <div className={styles.signInContainer}>
    <img src="/logo.jpg" alt={t('LOGO_ALT_TEXT')} className={styles.logo} />
    <h1 className={styles.signInTitle}>{t('WELCOME_TO_MANGA_READER')}</h1>
    <p className={styles.description}>{t('MANGA_READER_DESCRIPTION')}</p>
    <button onClick={handleSignIn} className={styles.googleSignInButton}>
      {t('SIGN_IN_WITH_GOOGLE')}
    </button>
  </div>
);

};

export default GoogleSignIn;
