import React from 'react';
import styles from './AddFolderCard.module.scss'; // Import styles correctly

const AddFolderCard = ({ onClick }) => {
  return (
    <div className={styles.folderCardContainer}>
      <div className={styles.folderCard} onClick={onClick}>
        <div className={styles.addFolderIcon}>+</div>
      </div>
    </div>
  );
};

export default AddFolderCard;
