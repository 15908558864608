import AWS from "aws-sdk";
import awsConfig from "../config/awsConfig";
import {CognitoUserPool, CognitoUser, CognitoUserAttribute} from "amazon-cognito-identity-js";
import { jwtDecode } from 'jwt-decode'; // npm install jwt-decode
import cognitoConfig from "../config/cognitoConfig";

// Initialize AWS Cognito User Pool
const userPool = new CognitoUserPool({
  UserPoolId: cognitoConfig.UserPoolId,
  ClientId: cognitoConfig.ClientId
});

// Retrieve current authenticated user
const getCurrentUser = () => {
  return userPool.getCurrentUser();
};

// Retrieve the current user's session token
export const getSessionToken = async () => {

  const currentUser = getCurrentUser();
  if (!currentUser) {
      throw new Error("No current user available.");
  }

  return new Promise((resolve, reject) => {
      currentUser.getSession((err, session) => {
          if (err) {
              reject(new Error("Error retrieving user session."));
          } else if (session.isValid()) {
              resolve(session.getIdToken().getJwtToken());
          } else {
              currentUser.refreshSession(session.getRefreshToken(), (refreshErr, newSession) => {
                  if (refreshErr) {
                      reject(new Error("Error refreshing tokens."));
                  } else {
                      resolve(newSession.getIdToken().getJwtToken());
                  }
              });
          }
      });
  });
};

const getCognitoIdentityCredentials = async () => {
  const token = await getSessionToken();
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: awsConfig.identityPoolId,
    Logins: {
      [`cognito-idp.${awsConfig.region}.amazonaws.com/${awsConfig.userPoolId}`]: token,
    },
  });

  return new Promise((resolve, reject) => {
    AWS.config.credentials.refresh((error) => {
      if (error) {
        reject(error);
      } else {
        resolve(AWS.config.credentials);
      }
    });
  });
};

export const getIdentityId = async () => {
  const token = await getSessionToken();
  const credentials = await getCognitoIdentityCredentials(token);
  return credentials.identityId;
};

export const getCognitoUserTokenCredits = async () => {
  try {
    const token = await getSessionToken();
    if (!token) throw new Error("No ID token found");

    const decodedToken = jwtDecode(token);
    const credits = decodedToken['custom:credits']; // Access custom attributes like this

    return credits; // This could be `null` if the attribute isn't set
  } catch (error) {
    console.error('Error retrieving user credits:', error);
    return null; // or handle error as needed
  }
};

export const getCognitoUserCredits = async () => {
  const token = await getSessionToken();
  const credentials = await getCognitoIdentityCredentials(token);
  AWS.config.update({
    region: awsConfig.region,
    credentials
  });

  const cognitoServiceProvider = new AWS.CognitoIdentityServiceProvider();
  const params = {
    AccessToken: token
  };

  return new Promise((resolve, reject) => {
      cognitoServiceProvider.getUser(params, (err, data) => {
        if (err) {
          console.error('Error fetching user data:', err);
          reject(err);
        } else {
          const creditsAttribute = data.UserAttributes.find(attr => attr.Name === 'custom:credits');
          resolve(creditsAttribute ? creditsAttribute.Value : null);
        }
      });
  });
};

// export const updateUserCredits = async (username, credits) => {
//   const token = localStorage.getItem('authToken');
//   const credentials = await getCognitoIdentityCredentials(token);
//   AWS.config.update({
//     region: awsConfig.region,
//     credentials
//   });
//
//   const cognitoServiceProvider = new AWS.CognitoIdentityServiceProvider();
//   const params = {
//     UserPoolId: awsConfig.userPoolId,
//     Username: username,
//     UserAttributes: [
//       {
//         Name: 'custom:credits',
//         Value: credits.toString()
//       }
//     ]
//   };
//
//   return new Promise((resolve, reject) => {
//     cognitoServiceProvider.adminUpdateUserAttributes(params, (err, data) => {
//       if (err) {
//         reject(err);
//       } else {
//         resolve(data); // Successfully updated the user's credits
//       }
//     });
//   });
//   refreshUserSession();
// };

// const refreshUserSession = () => {

//   const poolData = {
//     UserPoolId: 'eu-west-2_GlYm8QaQx',  // Your user pool id here
//     ClientId: '6t71b5h3on8k0sk6os8r2jj21'  // Your client id here
// };

//   const userPool = new CognitoUserPool(poolData);

//     const userData = {
//         Username: 'username', // Replace 'username' with the actual username
//         Pool: userPool
//     };

//     const cognitoUser = new CognitoUser(userData);

//     cognitoUser.getSession((err, session) => {
//         if (err) {
//             console.error(err);
//             return;
//         }

//         const refreshToken = session.getRefreshToken();

//         cognitoUser.refreshSession(refreshToken, (refreshErr, newSession) => {
//             if (refreshErr) {
//                 console.error(refreshErr);
//             } else {
//                 console.log('New ID Token:', newSession.getIdToken().getJwtToken());
//                 // The new session now has updated claims if attributes were changed
//             }
//         });
//     });
// };




export async function updateUserCredits(credits) {
  console.log("update User credits", credits)
}