import React, { useState } from 'react';
import styles from './AddFolderModal.module.scss';
import { useTranslation } from 'react-i18next'; // Import the hook

const AddFolderModal = ({ isOpen, onClose, onAddFolder }) => {
  const { t } = useTranslation();
  const [folderFiles, setFolderFiles] = useState([]);
  const [folderName, setFolderName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);  // State to manage submission status

  const handleFolderFilesChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length > 0) {
      setFolderFiles(files);
      setErrorMessage('');
    } else {
      setErrorMessage(t('NO_IMAGE_FILES_ERROR_MESSAGE'));
      setFolderFiles([]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!folderName.trim()) {
      setErrorMessage(t('EMPTY_FOLDER_NAME_ERROR_MESSAGE'));
      return;
    }
    if (folderFiles.length === 0) {
      setErrorMessage(t('NO_IMAGE_FILES_ERROR_MESSAGE'));
      return;
    }
    onAddFolder(folderName.trim(), folderFiles);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <h2>{t("ADD_NEW_FOLDER")}</h2>
        {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
        <form onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <label htmlFor="folderName">{t("FOLDER_NAME")}</label>
            <input
              type="text"
              id="folderName"
              value={folderName}
              onChange={(e) => setFolderName(e.target.value)}
              placeholder={t("FOLDER_NAME_PLACEHOLDER")}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="folderFiles">{t("SELECT_FILES")}</label>
            <input
              type="file"
              id="folderFiles"
              multiple
              accept="image/jpeg, image/png, image/jpg"
              onChange={handleFolderFilesChange}
            />
          </div>
          <div className={styles.formActions}>
            <button type="submit" disabled={isSubmitting}>{t("ADD_FOLDER")}</button>
            <button type="button" onClick={onClose}>{t("CANCEL")}</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddFolderModal;
