import React, { useState } from 'react';
import { updateUserData } from "../../services/dynamoDBService";
import ModalStyles from './UserPreferencesModal.module.scss';

const UserPreferencesModal = ({ isOpen, onClose }) => {
  const [translationLanguage, setTranslationLanguage] = useState('English');
  const [offlineMode, setOfflineMode] = useState(false);

  const handleSavePreferences = async () => {
    try {
      await updateUserData({
        translationLanguage,
        offlineMode
      });
      onClose(); // Close modal on successful update
    } catch (error) {
      console.error("Error updating preferences:", error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className={ModalStyles.modalOverlay}>
      <div className={ModalStyles.modalContent}>
        <h2>User Preferences</h2>
        <div className={ModalStyles.formGroup}>
          <label htmlFor="translationLanguage">Translation Language:</label>
          <select
            id="translationLanguage"
            value={translationLanguage}
            onChange={e => setTranslationLanguage(e.target.value)}
          >
            <option value="English">English</option>
            <option value="Spanish">Spanish</option>
            <option value="Japanese">Japanese</option>
          </select>
        </div>
        <div className={ModalStyles.formGroup}>
          <label>
            <input
              type="checkbox"
              checked={offlineMode}
              onChange={e => setOfflineMode(e.target.checked)}
            />
            Enable Offline Mode
          </label>
        </div>
        <button onClick={handleSavePreferences}>Save</button>
        <button onClick={onClose} className={ModalStyles.closeButton}>Close</button>
      </div>
    </div>
  );
};

export default UserPreferencesModal;
