import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePresignedUrl } from '../../services/s3Service';
import PhrasesOverlay from '../PhrasesOverlay/PhrasesOverlay';
import { scanPageLambda } from "../../api/scan_images";
import styles from './MangaImage.module.scss';
import { handleScanPage } from '../../utils/scanUtil';
import { CLOUDFRONT_URL } from "../../constants";
import { useUserCredits } from '../../context/UserCreditsContext';

const MangaImage = ({ page, setSelectedPhrase, handlePhraseClick, zoomLevel }) => {
  const { t } = useTranslation();
  const [imageUrl, setImageUrl] = useState('');
  const [jsonData, setJsonData] = useState(null);
  const [originalSize, setOriginalSize] = useState({ width: 0, height: 0 });
  const [displayedSize, setDisplayedSize] = useState({ width: 0, height: 0 });
  const imageRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isScanning, setIsScanning] = useState(false);
  const { credits, updateCredits } = useUserCredits();

  useEffect(() => {
    async function fetchData() {
      if (page.ItemPath) {
        const pageImageUrl = `${CLOUDFRONT_URL}/userData/${page.UserID}/${page.ItemPath}`;
        setImageUrl(pageImageUrl);
      }
      if (page.DataURL) {
        const dataUrl = await generatePresignedUrl(page.DataURL);
        fetchJsonData(dataUrl);
      } else {
        setJsonData(null); // Reset jsonData if DataURL is not available
      }
    }

    fetchData();
  }, [page]);

  const fetchJsonData = async (dataUrl) => {
    try {
      const response = await fetch(dataUrl);
      const data = await response.json();
      setJsonData(data);
    } catch (error) {
      console.error("Error fetching JSON data:", error);
    }
  };

  const handleImageLoad = (event) => {
    const { naturalWidth, naturalHeight, clientWidth, clientHeight } = event.target;
    setOriginalSize({ width: naturalWidth, height: naturalHeight });
    setDisplayedSize({ width: clientWidth, height: clientHeight });

    // Call applyZoom immediately after the image is loaded
    applyZoom();
  };

  const applyZoom = () => {
    if (imageRef.current) {
      let newWidth = originalSize.width;
      let newHeight = originalSize.height;

      switch (zoomLevel) {
        case 'width':
          newWidth = window.innerWidth;
          newHeight = (originalSize.height / originalSize.width) * newWidth;
          break;
        case 'height':
          newHeight = window.innerHeight;
          newWidth = (originalSize.width / originalSize.height) * newHeight;
          break;
        case 'halfHeight':
          newHeight = window.innerHeight * 2;
          newWidth = (originalSize.width / originalSize.height) * newHeight;
          break;
        default:
          newWidth = originalSize.width;
          newHeight = originalSize.height;
          break;
      }

      // Ensure newWidth does not exceed window.innerWidth
      if (newWidth > window.innerWidth) {
        const scale = window.innerWidth / newWidth;
        newWidth = window.innerWidth;
        newHeight = newHeight * scale;
      }

      setDisplayedSize({ width: newWidth, height: newHeight });
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (originalSize.width && originalSize.height) {
        applyZoom(); // Recalculate displayedSize
      }
    };

    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [originalSize.width, originalSize.height, zoomLevel]);

  useEffect(() => {
    // Apply zoom when the zoom level changes OR when the original size is set
    if (originalSize.width && originalSize.height) {
      applyZoom();
    }
  }, [zoomLevel, originalSize]);

  useEffect(() => {
    // Apply zoom immediately when zoomLevel changes
    applyZoom();
  }, [zoomLevel]);

  // Function to handle scanning of the page
  const handleScan = async () => {
    setIsScanning(true);
    setLoading(true);
    setError('');

    try {
      // Check if the user has enough credits
      if (credits <= 0) {
        setError(t('NOT_ENOUGH_CREDITS'));
        setLoading(false);
        setIsScanning(false);
        return;
      }

      const result = await handleScanPage(
        `userData/${page.UserID}/${page.ItemPath}`,
        setIsScanning,
        setLoading,
        setError,
        setJsonData,
        credits,
        updateCredits
      );

      if (result.creditsUsed) {
        // Update credits if necessary
        updateCredits(credits - result.creditsUsed);
      }
    } catch (err) {
      console.error("Error scanning page:", err);
      setError(t('SCAN_ERROR'));
    } finally {
      setLoading(false);
      setIsScanning(false);
    }
  };

  return (
    <div className={styles['manga-image-container']}>
      <div
        className={styles['image-overlay-container']}
        style={{
          width: `${displayedSize.width}px`,
          height: `${displayedSize.height}px`,
          margin: '0 auto', // Centers the container
        }}
      >
        <img
          ref={imageRef}
          src={imageUrl}
          alt={t('MANGA_PAGE_ALT', { page: page.basename || '' })}
          className={styles['manga-image']}
          onLoad={handleImageLoad}
          style={{
            width: '100%',
            height: '100%',
          }}
          onError={() => console.error(t('IMAGE_LOAD_FAIL'))}
        />
        {jsonData && (
          <PhrasesOverlay
            phrases={jsonData}
            page={{ originalSize, displayedSize }}
            handlePhraseClick={handlePhraseClick}
          />
        )}
      </div>
      {!jsonData && (
        <button
          className={`${styles['scan-button']} ${isScanning ? styles['disabled-button'] : ''}`}
          onClick={handleScan}
          disabled={isScanning}
        >
          {isScanning ? t('SCANNING') : t('SCAN_THIS_PAGE')}
        </button>
      )}
      {loading && <p>{t('LOADING')}</p>}
      {error && <p className={styles['error-message']}>{error}</p>}
    </div>
  );
};

export default MangaImage;
