// index.js
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { AuthProvider } from './context/AuthContext'; // Ensure this path is correct
import ErrorBoundary from "./ErrorBoundary";
import {I18nextProvider} from "react-i18next";
import i18n from "./i18n";
import { UserCreditsProvider } from './context/UserCreditsContext'; // Import CreditsProvider


ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
        <AuthProvider>
          <I18nextProvider i18n={i18n}>
            <UserCreditsProvider>
              <App />
            </UserCreditsProvider>
          </I18nextProvider>
        </AuthProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);
