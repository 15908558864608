import React, { useState, useEffect, useRef } from 'react';
import styles from './UserCredits.module.scss';
import BuyCreditsModal from '../BuyCreditsModal/BuyCreditsModal';
import { updateUserCredits } from '../../services/cognitoService';
import { useTranslation } from 'react-i18next';
import { useUserCredits } from '../../context/UserCreditsContext';

const UserCredits = () => {
  const { t } = useTranslation();
  const { credits, updateCredits } = useUserCredits();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleBuyCredits = async (additionalCredits) => {
    try {
      const updatedCredits = await updateUserCredits(credits + additionalCredits);
      updateCredits(updatedCredits);
    } catch (error) {
      console.error(t('Error updating credits:'), error);
    }
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className={styles.creditsContainer} ref={dropdownRef}>
      <div onClick={toggleDropdown} className={styles.creditsInfo}>
        <img
          src="/currency.png"
          alt={t('Credits Icon')}
          className={styles.creditIcon}
          title={t('Credits')}
        />
        <span className={styles.creditsText}>{credits}</span>
        <span className={styles.dropdownArrow}>&#9660;</span>
      </div>
      {isDropdownOpen && (
        <ul className={styles.creditsDropdown}>
          <li onClick={() => handleBuyCredits(5)}>{t('Buy 5 Credits')}</li>
          <li onClick={() => handleBuyCredits(10)}>{t('Buy 10 Credits')}</li>
          <li onClick={() => handleBuyCredits(20)}>{t('Buy 20 Credits')}</li>
        </ul>
      )}
    </div>
  );
};

export default UserCredits;
