import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext'; // Use AuthContext
import { updateUserData } from '../../services/dynamoDBService'; // Ensure correct imports
import styles from './TermsAndConditions.module.scss';
import { getUserPreferences, saveUserPreferences } from "../../utils/indexedDB";
import {LATEST_TERMS_VERSION} from './../../constants'


const TermsAndConditions = () => {
  const navigate = useNavigate();
  const { user, setUser } = useAuth();

  const handleAccept = async () => {
    try {
      const updatedUserData = { ...user, termsVersion: LATEST_TERMS_VERSION };
      await updateUserData({ termsVersion: LATEST_TERMS_VERSION });
      await saveUserPreferences(updatedUserData);
      setUser(updatedUserData);
      navigate('/library');
    } catch (error) {
      console.error('Error accepting terms:', error);
    }
  };
  

  return (
    <div className={styles.container}>
      <h1>Terms and Conditions</h1>
      <div className={styles.termsContent}>
        {/* Section 1 */}
        <div className={styles.section}>
          <h2>1. Acceptance of Terms</h2>
          <p>
            By accessing or using <strong>[Your App Name]</strong> (“the App”), you agree to be bound by these Terms and Conditions (“Terms”). If you do not agree to these Terms, you must not use the App.
          </p>
        </div>

        {/* Section 2 */}
        <div className={styles.section}>
          <h2>2. Description of Service</h2>
          <p>
            The App provides users with tools for personal translation and language learning purposes, including optical character recognition (OCR) and translation services applied to content uploaded by users (“User Content”). The App does not provide any content and does not distribute or share User Content publicly.
          </p>
        </div>

        {/* Section 3 */}
        <div className={styles.section}>
          <h2>3. Eligibility</h2>
          <p>
            You must be at least 13 years old to use the App. By using the App, you represent and warrant that you have the legal capacity to enter into these Terms.
          </p>
        </div>

        {/* Section 4 */}
        <div className={styles.section}>
          <h2>4. User Responsibility for Content</h2>
          <h3>4.1. Ownership and Rights</h3>
          <ul>
            <li>
              You represent and warrant that you own all rights, title, and interest in and to the User Content you upload, or that you have obtained all necessary rights and permissions from the rightful owner to use, upload, and process the User Content through the App.
            </li>
          </ul>
          <h3>4.2. Compliance with Laws</h3>
          <ul>
            <li>
              You agree not to upload any content that infringes upon any third party's intellectual property rights, privacy rights, or any other legal rights.
            </li>
            <li>
              You are solely responsible for ensuring that your use of the App and any User Content complies with all applicable laws and regulations.
            </li>
          </ul>
          <h3>4.3. Indemnification</h3>
          <ul>
            <li>
              You agree to indemnify, defend, and hold harmless [Your App Name], its affiliates, officers, directors, employees, agents, and licensors from any claims, losses, liabilities, damages, expenses, or costs arising out of or related to your User Content, your use of the App, or your violation of these Terms.
            </li>
          </ul>
        </div>

        {/* Section 5 */}
        <div className={styles.section}>
          <h2>5. Prohibited Activities</h2>
          <p>You agree not to:</p>
          <ul>
            <li>Use the App for any illegal or unauthorized purposes.</li>
            <li>
              Upload, post, or transmit any content that is unlawful, defamatory, obscene, pornographic, harassing, threatening, or otherwise objectionable.
            </li>
            <li>
              Attempt to interfere with or disrupt the App or any servers or networks connected to the App.
            </li>
            <li>
              Circumvent, disable, or otherwise interfere with security-related features of the App.
            </li>
          </ul>
        </div>

        {/* Section 6 */}
        <div className={styles.section}>
          <h2>6. Intellectual Property Rights</h2>
          <h3>6.1. App Content</h3>
          <p>
            All content and materials available on the App, excluding User Content, are the property of [Your App Name] and are protected by applicable intellectual property laws. Unauthorized use of the App Content is prohibited.
          </p>
          <h3>6.2. User Content</h3>
          <p>
            We do not claim ownership of User Content. By uploading User Content, you grant us a non-exclusive, royalty-free, worldwide license to use, store, and process your User Content solely for the purpose of providing the services offered by the App.
          </p>
        </div>

        {/* Section 7 */}
        <div className={styles.section}>
          <h2>7. Privacy</h2>
          <p>
            Your use of the App is also governed by our Privacy Policy, which explains how we collect, use, and protect your personal information. By using the App, you consent to the collection and use of information in accordance with the Privacy Policy.
          </p>
        </div>

        {/* Section 8 */}
        <div className={styles.section}>
          <h2>8. Disclaimer of Warranties</h2>
          <p>
            The App is provided on an “as is” and “as available” basis. We make no warranties, express or implied, regarding the App’s operation or your use of the App. We do not guarantee that the App will be error-free, secure, or uninterrupted.
          </p>
        </div>

        {/* Section 9 */}
        <div className={styles.section}>
          <h2>9. Limitation of Liability</h2>
          <p>
            To the maximum extent permitted by law, [Your App Name] shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of the App. Our total liability to you for any damages shall not exceed the amount you have paid, if any, to use the App.
          </p>
        </div>

        {/* Section 10 */}
        <div className={styles.section}>
          <h2>10. Notice and Takedown Procedure</h2>
          <h3>10.1. Reporting Infringement</h3>
          <p>
            If you believe that any content on the App infringes upon your intellectual property rights, please notify us immediately at <strong>[Your Contact Email]</strong> with the following information:
          </p>
          <ul>
            <li>A description of the intellectual property you claim has been infringed.</li>
            <li>A description of where the infringing material is located on the App.</li>
            <li>Your contact information (name, address, telephone number, email address).</li>
            <li>
              A statement by you that you have a good faith belief that the disputed use is not authorized by the intellectual property owner, its agent, or the law.
            </li>
            <li>
              An electronic or physical signature of the person authorized to act on behalf of the owner of the intellectual property interest.
            </li>
          </ul>
          <h3>10.2. Removal of Content</h3>
          <p>
            Upon receiving a valid notice, we will promptly remove the infringing content and notify the user responsible for the content.
          </p>
        </div>

        {/* Section 11 */}
        <div className={styles.section}>
          <h2>11. Modifications to Terms</h2>
          <p>
            We reserve the right to modify these Terms at any time. We will notify users of significant changes by posting a notice within the App or by other means. Your continued use of the App after changes have been made constitutes your acceptance of the new Terms.
          </p>
        </div>

        {/* Section 12 */}
        <div className={styles.section}>
          <h2>12. Termination</h2>
          <p>
            We may terminate or suspend your access to the App at any time, without prior notice, for conduct that we believe violates these Terms or is harmful to other users or the App. Upon termination, all provisions of these Terms which by their nature should survive termination shall survive.
          </p>
        </div>

        {/* Section 13 */}
        <div className={styles.section}>
          <h2>13. Governing Law</h2>
          <p>
            These Terms shall be governed by and construed in accordance with the laws of <strong>[Your Country/State]</strong>, without regard to its conflict of law provisions.
          </p>
        </div>

        {/* Section 14 */}
        <div className={styles.section}>
          <h2>14. Dispute Resolution</h2>
          <p>
            Any disputes arising out of or relating to these Terms or the App shall be resolved through binding arbitration in accordance with the rules of <strong>[Arbitration Organization]</strong>, and judgment upon the award rendered by the arbitrator(s) may be entered in any court having jurisdiction thereof.
          </p>
        </div>

        {/* Section 15 */}
        <div className={styles.section}>
          <h2>15. Limitation on Time to File Claims</h2>
          <p>
            Any cause of action or claim you may have arising out of or relating to these Terms or the App must be commenced within one (1) year after the cause of action accrues; otherwise, such cause of action or claim is permanently barred.
          </p>
        </div>

        {/* Section 16 */}
        <div className={styles.section}>
          <h2>16. Entire Agreement</h2>
          <p>
            These Terms constitute the entire agreement between you and [Your App Name] regarding the App and supersede all prior agreements.
          </p>
        </div>

        {/* Section 17 */}
        <div className={styles.section}>
          <h2>17. Waiver and Severability</h2>
          <p>
            The failure of [Your App Name] to enforce any right or provision of these Terms shall not constitute a waiver of such right or provision. If any provision of these Terms is held to be invalid or unenforceable, the remaining provisions shall remain in full force and effect.
          </p>
        </div>

        {/* Section 18 */}
        <div className={styles.section}>
          <h2>18. Contact Information</h2>
          <p>
            If you have any questions or concerns about these Terms, please contact us at:
          </p>
          <p>
            <strong>[Your App Name]</strong>
            <br />
            Email: <strong>[Your Contact Email]</strong>
            <br />
            Address: <strong>[Your Physical Address (if applicable)]</strong>
          </p>
        </div>

        {/* Disclaimer */}
        <div className={styles.section}>
          <p>
            <em>
              Disclaimer: This Terms and Conditions template is provided for informational purposes only and does not constitute legal advice. [Your App Name] makes no representations or warranties, express or implied, as to the legal effect or completeness of this document. You should consult with a qualified attorney to ensure that your Terms and Conditions comply with all applicable laws and are tailored to your specific needs.
            </em>
          </p>
        </div>
      </div>
      <button onClick={handleAccept} className={styles.acceptButton}>
        I Accept
      </button>
    </div>
  );
};

export default TermsAndConditions;
