import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './LanguageSelector.module.scss';

function LanguageSelector() {
  const { i18n } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setIsDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className={styles.languageSelector}>
      <div onClick={toggleDropdown} className={styles.languageButton}>
        <img src="/globe.png" alt="Language" className={styles.languageIcon} />
        <span>{i18n.language.toUpperCase()}</span>
        <span className={styles.dropdownArrow}>&#9660;</span>
      </div>
      {isDropdownOpen && (
        <ul className={styles.languageDropdown}>
          <li onClick={() => changeLanguage('en')}>English</li>
          <li onClick={() => changeLanguage('fr')}>Français</li>
          {/* Add more languages as needed */}
        </ul>
      )}
    </div>
  );
}

export default LanguageSelector;
