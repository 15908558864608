import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './GeneralHeader.module.scss';
import ProfileAvatar from '../ProfileAvatar/ProfileAvatar';
import UserCredits from '../UserCredits/UserCredits';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import { useTranslation } from 'react-i18next';
import HeaderContext from '../../context/HeaderContext';

const GeneralHeader = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { headerState } = useContext(HeaderContext);

  const [showHeader, setShowHeader] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const visible = prevScrollPos > currentScrollPos || currentScrollPos < 10;

    setShowHeader(visible);
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);

  const goBackToHome = () => {
    navigate('/');
  };

  return (
    <div
      className={`${styles.header} ${showHeader ? styles.headerVisible : styles.headerHidden}`}
      onMouseEnter={() => setShowHeader(true)}
    >
      <div className={styles.headerContent}>
        <button onClick={goBackToHome} className={styles.headerLogoButton} aria-label={t('HOME')}>
          <img src="/logo.jpg" alt={t('HOME')} className={styles.logoImage} />
        </button>
        <UserCredits />
        {!isMobile && headerState.showZoomButtons && (
          <div className={styles.zoomButtons}>
            <button onClick={() => headerState.onZoom('width')} className={styles.headerButton}>
              {t('Fit to Width')}
            </button>
            <button onClick={() => headerState.onZoom('height')} className={styles.headerButton}>
              {t('Fit to Height')}
            </button>
            <button onClick={() => headerState.onZoom('halfHeight')} className={styles.headerButton}>
              {t('200% Height')}
            </button>
            <button onClick={headerState.onScanAllPages} className={styles.headerButton}>
              {t('Scan All Pages')}
            </button>
          </div>
        )}
        <LanguageSelector />
        <span className={styles.version}>Version 0.4</span>
        <ProfileAvatar />
      </div>
    </div>
  );
};

export default GeneralHeader;
