import { LambdaClient, InvokeCommand } from "@aws-sdk/client-lambda";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import awsConfig from '../config/awsConfig';
import { getSessionToken } from '../services/cognitoService';
// Function to create a Lambda client using Cognito credentials
const createLambdaClient = (token) => {
  return new LambdaClient({
    region: awsConfig.region,
    credentials: fromCognitoIdentityPool({
      clientConfig: { region: awsConfig.region },
      identityPoolId: awsConfig.identityPoolId,
      logins: {
        [`cognito-idp.${awsConfig.region}.amazonaws.com/${awsConfig.userPoolId}`]: token,
      },
    }),
  });
};

// Function to invoke a Lambda function
const invokeLambda = async ({functionName, payload}) => {
  const token = await getSessionToken();
  const lambdaClient = createLambdaClient(token);
  const command = new InvokeCommand({
    FunctionName: functionName,
    Payload: JSON.stringify(payload) // Ensure payload is properly encoded as bytes
  });

  try {
    const response = await lambdaClient.send(command);
    const responseBody = new TextDecoder('utf-8').decode(response.Payload);
    return JSON.parse(responseBody);
  } catch (error) {
    console.error('Error invoking Lambda function:', error);
    throw new Error(`Error invoking Lambda function: ${error}`);
  }
};

export { invokeLambda };
