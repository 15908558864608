import React, { useState, useRef, useEffect } from 'react';
import styles from './ProfileAvatar.module.scss';
import { useAuth } from '../../context/AuthContext';
import UserPreferencesModal from '../UserPreferencesModal/UserPreferencesModal';

const ProfileAvatar = () => {
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [isPreferencesOpen, setIsPreferencesOpen] = useState(false);
  const { signOut } = useAuth();
  const menuRef = useRef(null);

  const toggleProfileMenu = () => {
    setIsProfileMenuOpen(!isProfileMenuOpen);
  };

  const openPreferences = () => {
    setIsPreferencesOpen(true);
    setIsProfileMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsProfileMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className={styles.profileActions}>
      <img src="/avatar.png" alt="Profile" className={styles.profileAvatar} onClick={toggleProfileMenu} />
      {isProfileMenuOpen && (
        <ul className={styles.profileMenu} ref={menuRef}>
          <li onClick={openPreferences}>Preferences</li>
          <li onClick={signOut}>Sign Out</li>
        </ul>
      )}
      <UserPreferencesModal isOpen={isPreferencesOpen} onClose={() => setIsPreferencesOpen(false)} />
    </div>
  );
};

export default ProfileAvatar;
