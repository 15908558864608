import AWS from 'aws-sdk';

const awsConfig = {
  region: 'eu-west-2', // Replace with your region
  userPoolId: 'eu-west-2_GlYm8QaQx', // Replace with your User Pool ID
  userPoolWebClientId: '6t71b5h3on8k0sk6os8r2jj21', // Replace with your App Client ID
  identityPoolId: 'eu-west-2:53cdd33d-2ecc-465a-930e-ea3eaa73fa80', // Replace with your Identity Pool ID,
  bucketName: "ocr-manga-reader-images"
};

AWS.config.update({
  region: awsConfig.region,
});


export default awsConfig
