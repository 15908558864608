import React, { createContext, useState, useContext, useEffect } from 'react';
import { getUserData } from "../services/dynamoDBService";

// Create a context
const UserCreditsContext = createContext();

// Context provider component
export const UserCreditsProvider = ({ children }) => {
  const [credits, setCredits] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      const userData = await getUserData();
      setCredits(userData?.Credits || 0);
    };
    fetchUserData();
  }, []);

  const updateCredits = (newCredits) => {
    setCredits(newCredits);
    // Optionally, you can also persist this change to the server here.
  };

  return (
    <UserCreditsContext.Provider value={{ credits, updateCredits }}>
      {children}
    </UserCreditsContext.Provider>
  );
};

// Custom hook to use the context
export const useUserCredits = () => useContext(UserCreditsContext);
