import React from 'react';
import styles from './YesNoMessageModal.module.scss'; // SCSS file for styling
import { useTranslation } from 'react-i18next'; // Import the hook

const YesNoMessageModal = ({ isOpen, onClose, onConfirm, message }) => {
  const { t } = useTranslation(); // Initialize the translation hook

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <p>{message}</p>
        <div className={styles.modalActions}>
          <button onClick={onConfirm} className={styles.modalYes}>{t('Yes')}</button>
          <button onClick={onClose} className={styles.modalNo}>{t('No')}</button>
        </div>
      </div>
    </div>
  );
};

export default YesNoMessageModal;
