import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ProtectedImage } from './../ProtectedImage/ProtecedImage'
import { CLOUDFRONT_URL } from '../../constants';
import styles from './FolderCard.module.scss';

const FolderCard = ({ folder, isLoading, onDelete, onEdit }) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    if (!isLoading) {
      navigate(`/manga/${folder.FolderName}`);
    }
  };

  return (
    <div className={styles['folder-card-container']}>
      <div
        className={`${styles['folder-card']} ${isLoading ? styles.loading : ''}`}
        onClick={handleNavigate}
      >
        {isLoading ? (
          <div className={styles['loading-placeholder']}>Loading...</div>
        ) : (
          <>
            {folder.CoverImage ? (
              <ProtectedImage
                src={`${CLOUDFRONT_URL}/userData/${folder.UserID}/${folder.CoverImage}`}
                className={styles['folder-cover-image']}
              />
            ) : (
              <img src="/no_cover.webp" alt="No Cover" className={styles['folder-cover-image']} />
            )}
            <div className={styles['folder-info']}>
              <h2>{folder?.FolderName}</h2>
            </div>
            {/* Edit Icon */}
            <img
              src={"icons/edit.png"}
              alt="Edit"
              className={styles['edit-icon']}
              onClick={(e) => {
                e.stopPropagation();
                onEdit(folder.FolderName);
              }}
            />
            {/* Delete Icon */}
            <img
              src={"icons/bin.png"}
              alt="Delete"
              className={styles['delete-icon']}
              onClick={(e) => {
                e.stopPropagation();
                onDelete(folder.FolderName);
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default FolderCard;
