import React from 'react';
import MangaViewer from '../components/MangaViewer/MangaViewer';

const MangaViewerPage = () => {
  return (
      <MangaViewer />

  );
};

export default MangaViewerPage;
