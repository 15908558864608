// import React, { useState, useRef, useEffect, useContext, useCallback } from 'react';
// import { useParams, useNavigate } from 'react-router-dom';
// import { useFetchPages } from '../../hooks/useFetchPages';
// import MangaImage from '../MangaImage/MangaImage';
// import SideBar from '../SideBar/SideBar';
// import './MangaViewer.module.scss';
// import HeaderContext from '../../context/HeaderContext';

// const MangaViewer = () => {
//   const { setHeaderState } = useContext(HeaderContext);
//   const { folderName } = useParams();
//   const navigate = useNavigate();
//   const [pages, setPages] = useState([]);
//   const [selectedPhrase, setSelectedPhrase] = useState(null);
//   const [selectedWord, setSelectedWord] = useState(null);
//   const [loadingState, setLoadingState] = useState({});
//   const [sidebarOpen, setSidebarOpen] = useState(false);
//   const [loadingMore, setLoadingMore] = useState(false);
//   const [zoomLevel, setZoomLevel] = useState('width'); // Default zoom level
//   const [isRestoringPage, setIsRestoringPage] = useState(true);

//   const viewerRef = useRef(null);
//   const [currentPageIndex, setCurrentPageIndex] = useState(0);
//   const pageRefs = useRef([]);
  
//   useEffect(() => {
//     // Set header state when the component mounts
//     setHeaderState({
//       showZoomButtons: true,
//       onZoom: handleZoom,
//       onScanAllPages: _scanAllPages,
//     });

//     // Clean up header state when the component unmounts
//     return () => {
//       setHeaderState({});
//     };
//   }, []);

//   useEffect(() => {
//   if (!pages.length) return;

//   const options = {
//     root: null, // Use the viewport as root
//     rootMargin: '0px',
//     threshold: 0.5, // Adjust based on when you consider a page "in view"
//   };

//   const observer = new IntersectionObserver((entries) => {
//     entries.forEach((entry) => {
//       if (entry.isIntersecting) {
//         const index = Number(entry.target.dataset.index);
//         setCurrentPageIndex(index);
//         localStorage.setItem(`currentPage_${folderName}`, index);
//         console.log(`currentPage_${folderName}:`, index);
//       }
//     });
//   }, options);

//   pageRefs.current.forEach((ref, index) => {
//     if (ref) {
//       ref.dataset.index = index;
//       observer.observe(ref);
//     }
//   });

//   return () => {
//     observer.disconnect();
//   };
// }, [pages, folderName]);


//   // Fetch the pages using custom hook
//   const { fetchPages, nextToken, hasPreviousPages } = useFetchPages(folderName, setPages, setLoadingState);

//   const loadMorePages = () => {
//     if (nextToken && !loadingState.loading) {
//       fetchPages(nextToken, 'next');
//     }
//   };

//   const loadPreviousPages = () => {
//     if (hasPreviousPages && !loadingState.loading) {
//       fetchPages(null, 'previous');
//     }
//   };

//   const handleZoom = (level) => {
//     setZoomLevel(level);
//   };

//   const handleImageLoad = (imageId) => {
//     // Existing handleImageLoad implementation
//     setPages(prevPages => prevPages.map(page => {
//       if (page.image_url.split('/').pop() === imageId) {
//         const imgElement = document.querySelector(`img[data-image-id="${imageId}"]`);
//         if (imgElement) {
//           return {
//             ...page,
//             displayedSize: {
//               width: imgElement.clientWidth,
//               height: imgElement.clientHeight,
//             }
//           };
//         }
//       }
//       return page;
//     }));
//   };

//   const handlePhraseClick = (phrase) => {
//     setSelectedPhrase(phrase);
//     setSelectedWord(null);
//     setSidebarOpen(true);
//   };

//   const handleCloseSidebar = () => {
//     setSidebarOpen(false);
//   };

//   const handleWordClick = (word) => {
//     setSelectedWord(word);
//   };

//   const goBackToPhrases = () => {
//     setSelectedWord(null);
//   };

//   const goBackToPanels = () => {
//     setSelectedPhrase(null);
//   };

//   const goBackToHome = () => {
//     navigate('/');
//   };

//   const _scanAllPages = async () => {
//     // await scanAllPages(pages);
//     console.log("scanning")
//   };

//   // Store current page index in localStorage
//   const handleScroll = useCallback(() => {
//     let currentPageIndex = 0;
//     let minDistance = Infinity;
//     const viewportMiddle = window.innerHeight / 2;
  
//     pages.forEach((page, index) => {
//       const pageRef = pageRefs.current[index];
//       if (pageRef) {
//         const rect = pageRef.getBoundingClientRect();
//         console.log(`Page ${index}: rect =`, rect);
//         const distance = Math.abs(rect.top + rect.height / 2 - viewportMiddle);
//         console.log(`Page ${index}: distance = ${distance}`);

//         if (distance < minDistance) {
//           minDistance = distance;
//           currentPageIndex = index;
//         }
//       }
//     });
  
//     localStorage.setItem(`currentPage_${folderName}`, currentPageIndex);
//     console.log(`currentPage_${folderName}:`, currentPageIndex);
//   }, [pages, folderName]);

//   useEffect(() => {
//     window.addEventListener('scroll', handleScroll);
//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, [handleScroll]);

//   // Scroll to saved page on component mount
//   useEffect(() => {
//     const restorePage = async () => {
//       const savedPageIndex = localStorage.getItem(`currentPage_${folderName}`);
//       if (savedPageIndex !== null) {
//         let pageIndex = parseInt(savedPageIndex, 10);
  
//         // Load pages until we reach the saved page
//         while (pages.length <= pageIndex && nextToken) {
//           await fetchPages(nextToken, 'next');
//         }
  
//         // Ensure pageIndex is within bounds
//         pageIndex = Math.min(pageIndex, pages.length - 1);
  
//         const pageRef = pageRefs.current[pageIndex];
//         if (pageRef) {
//           window.scrollTo({
//             top: pageRef.offsetTop,
//             behavior: 'auto',
//           });
//         }
//       }
//       setIsRestoringPage(false);
//     };
  
//     if (isRestoringPage && pages.length > 0) {
//       restorePage();
//     }
//   }, [pages, folderName, isRestoringPage]);
  
  

//   return (
//     <div className="manga-viewer-container">
//       <div className="page-container">
//         {hasPreviousPages && (
//           <button onClick={loadPreviousPages} disabled={loadingMore}>
//             Load Previous Pages
//           </button>
//         )}
//         {pages.map((page, index) => (
//           <div
//           key={index}
//           ref={(el) => (pageRefs.current[index] = el)}
//           >
//             <MangaImage
//               page={page}
//               handleImageLoad={handleImageLoad}
//               handlePhraseClick={handlePhraseClick}
//               setSelectedPhrase={setSelectedPhrase}
//               zoomLevel={zoomLevel}
//             />
//           </div>
//         ))}
//         {nextToken && (
//           <button onClick={loadMorePages} disabled={loadingMore}>
//             Load More Pages
//           </button>
//         )}
//       </div>
//       <SideBar
//         isOpen={sidebarOpen}
//         selectedPhrase={selectedPhrase}
//         selectedWord={selectedWord}
//         goBackToPanels={goBackToPanels}
//         goBackToPhrases={goBackToPhrases}
//         handleWordClick={handleWordClick}
//         onClose={handleCloseSidebar}
//       />
//       {loadingState.loading && <div>Loading...</div>}
//     </div>
//   );
// };

// export default MangaViewer;



import React, { useState, useRef, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useFetchPages } from '../../hooks/useFetchPages';
import MangaImage from '../MangaImage/MangaImage';
import SideBar from '../SideBar/SideBar';
import './MangaViewer.module.scss';
import HeaderContext from '../../context/HeaderContext';

const MangaViewer = () => {
  const { setHeaderState } = useContext(HeaderContext);
  const { folderName } = useParams();
  const navigate = useNavigate();
  const [pages, setPages] = useState([]);
  const [selectedPhrase, setSelectedPhrase] = useState(null);
  const [selectedWord, setSelectedWord] = useState(null);
  const [loadingState, setLoadingState] = useState({});
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [zoomLevel, setZoomLevel] = useState('width');

  const viewerRef = useRef(null);
  const pageRefs = useRef([]);

  useEffect(() => {
    setHeaderState({
      showZoomButtons: true,
      onZoom: handleZoom,
      onScanAllPages: _scanAllPages,
    });

    return () => {
      setHeaderState({});
    };
  }, []);

  const { fetchPages, nextToken } = useFetchPages(folderName, setPages, setLoadingState);

  const handleZoom = (level) => {
    setZoomLevel(level);
  };

  const handleImageLoad = (imageId) => {
    // Existing handleImageLoad implementation
    setPages(prevPages => prevPages.map(page => {
      if (page.image_url.split('/').pop() === imageId) {
        const imgElement = document.querySelector(`img[data-image-id="${imageId}"]`);
        if (imgElement) {
          return {
            ...page,
            displayedSize: {
              width: imgElement.clientWidth,
              height: imgElement.clientHeight,
            }
          };
        }
      }
      return page;
    }));
  };

  const handlePhraseClick = (phrase) => {
    setSelectedPhrase(phrase);
    setSelectedWord(null);
    setSidebarOpen(true);
  };

  const handleCloseSidebar = () => {
    setSidebarOpen(false);
  };

  const handleWordClick = (word) => {
    setSelectedWord(word);
  };

  const goBackToPhrases = () => {
    setSelectedWord(null);
  };

  const goBackToPanels = () => {
    setSelectedPhrase(null);
  };

  const goBackToHome = () => {
    navigate('/');
  };

  const _scanAllPages = async () => {
    // await scanAllPages(pages);
  };

  // Scroll to saved page on component mount
  useEffect(() => {
    const savedPageIndexStr = localStorage.getItem(`currentPage_${folderName}`);
    if (savedPageIndexStr !== null && pages.length > 0) {
      const pageIndex = parseInt(savedPageIndexStr, 10);
      if (pageIndex >= 0 && pageIndex < pages.length) {
        const pageRef = pageRefs.current[pageIndex];
        if (pageRef) {
          window.scrollTo({
            top: pageRef.offsetTop,
            behavior: 'auto',
          });
        }
      }
    }
  }, [pages, folderName]);

  return (
    <div className="manga-viewer-container">
      <div className="page-container">
        {pages.map((page, index) => (
          <div
            key={index}
            ref={(el) => (pageRefs.current[index] = el)}
            data-index={index}
          >
            <MangaImage
              page={page}
              handleImageLoad={handleImageLoad}
              handlePhraseClick={handlePhraseClick}
              setSelectedPhrase={setSelectedPhrase}
              zoomLevel={zoomLevel}
            />
          </div>
        ))}
        {nextToken && (
          <button onClick={() => fetchPages(nextToken, 'next')} disabled={loadingState.loading}>
            Load More Pages
          </button>
        )}
      </div>
      <SideBar
        isOpen={sidebarOpen}
        selectedPhrase={selectedPhrase}
        selectedWord={selectedWord}
        goBackToPanels={goBackToPanels}
        goBackToPhrases={goBackToPhrases}
        handleWordClick={handleWordClick}
        onClose={handleCloseSidebar}
      />
      {loadingState.loading && <div>Loading...</div>}
    </div>
  );
};

export default MangaViewer;
