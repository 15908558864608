import React, { useState } from 'react';
import styles from './EditFolderModal.module.scss';
import { useTranslation } from 'react-i18next';

const EditFolderModal = ({ isOpen, onClose, folderName, onAddFiles }) => {
  const { t } = useTranslation();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length > 0) {
      setSelectedFiles(files);
      setErrorMessage('');
    } else {
      setErrorMessage(t('NO_IMAGE_FILES_ERROR_MESSAGE'));
      setSelectedFiles([]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedFiles.length === 0) {
      setErrorMessage(t('NO_IMAGE_FILES_ERROR_MESSAGE'));
      return;
    }
    setIsSubmitting(true);
    try {
      await onAddFiles(folderName, selectedFiles);
      onClose();
    } catch (error) {
      console.error('Error adding files:', error);
      setErrorMessage(t('ADD_FILES_ERROR_MESSAGE'));
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <h2>{t('ADD_FILES_TO_FOLDER', { folderName })}</h2>
        {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
        <form onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <label htmlFor="folderFiles">{t('SELECT_FILES')}</label>
            <input
              type="file"
              id="folderFiles"
              multiple
              accept="image/jpeg, image/png, image/jpg"
              onChange={handleFileChange}
            />
          </div>
          <div className={styles.formActions}>
            <button type="submit" disabled={isSubmitting}>
              {isSubmitting ? t('ADDING_FILES') : t('ADD_FILES')}
            </button>
            <button type="button" onClick={onClose}>
              {t('CANCEL')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditFolderModal;
