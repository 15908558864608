import React, { useState, useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.scss';
import FolderManager from './pages/FolderManager';
import GoogleSignIn from './components/GoogleSignIn/GoogleSignIn';
import MangaViewerPage from "./pages/MangaViewerPage";
import { useAuth } from './context/AuthContext';
// import AnimatedBackground from "./utils/animatedBackground";
import { getUserPreferences, saveUserPreferences } from "./utils/indexedDB";
import { getUserData, updateUserData } from "./services/dynamoDBService";
import GeneralHeader from './components/GeneralHeader/GeneralHeader'; // Import GeneralHeader
import { HeaderProvider } from './context/HeaderContext'; // Import HeaderProvider
import TermsAndConditions from './components/TermsAndConditions/TermsAndConditions'; // Import the component
import {LATEST_TERMS_VERSION} from './constants'

// Import the checkAndLoadData function
import { checkAndLoadData } from './utils/kanjiDatabase'; // Adjust the path if necessary

const DEFAULT_USER_OBJECT = {
  Credits: 5,
  TranslationLanguage: "English",
  OfflineMode: false,
  termsVersion: null, // Initialize as null or "0.0"
};


function App() {
  const { isAuthenticated, user, setUser } = useAuth();
  const [isDatabaseInitialized, setIsDatabaseInitialized] = useState(false); // Track database initialization

  useEffect(() => {
    if (isAuthenticated) {
      const loadData = async () => {
        try {
          // Start both asynchronous operations in parallel
          const dbInitPromise = checkAndLoadData();
          const userDataPromise = getUserPreferences();

          // Wait for user preferences to load
          let userData = await userDataPromise;

          if (!userData) {
            // Fetch user data from the server
            const dbUserData = await getUserData();

            if (!dbUserData) {
              await updateUserData(DEFAULT_USER_OBJECT);
              await saveUserPreferences(DEFAULT_USER_OBJECT);
              setUser(DEFAULT_USER_OBJECT);
            } else {
              await saveUserPreferences(dbUserData);
              setUser(dbUserData);
            }
          } else {
            setUser(userData);
          }

          // Wait for the database initialization to complete
          await dbInitPromise;
          console.log('Kanji database initialized.');
          setIsDatabaseInitialized(true);
        } catch (error) {
          console.error('Error during initialization:', error);
        }
      };
      loadData();
    }
  }, [isAuthenticated, setUser]);

  // Redirect to login if not authenticated
  if (!isAuthenticated) {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<GoogleSignIn />} />
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
      </Router>
    );
  }

  // Wait for user data and database to load before rendering the app
  if (!user || !isDatabaseInitialized) {
    return <div>Loading...</div>;
  }

  // Check if the user has accepted the latest terms
  if (user.termsVersion !== LATEST_TERMS_VERSION) {
    return (
      <Router>
        <Routes>
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="*" element={<Navigate replace to="/terms" />} />
        </Routes>
      </Router>
    );
  }
  

  return (
    <Router>
      <HeaderProvider>
        <GeneralHeader />
        <div className="App">
          <Routes>
            <Route path="/library" element={<FolderManager />} />
            <Route path="/manga/:folderName" element={<MangaViewerPage />} />
            <Route path="/" element={<Navigate replace to="/library" />} />
            <Route path="*" element={<Navigate replace to="/library" />} />
          </Routes>
        </div>
      </HeaderProvider>
    </Router>
  );
}

export default App;
