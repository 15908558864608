import React, { createContext, useContext, useState, useEffect } from 'react';
import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import cognitoConfig from "../config/cognitoConfig";

const poolData = {
    UserPoolId: cognitoConfig.UserPoolId,
    ClientId: cognitoConfig.ClientId
};

const userPool = new CognitoUserPool(poolData);
const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const [user, setUser] = useState(null);

    const checkSession = () => {
        const user = userPool.getCurrentUser();
        if (!user) {
            console.log("No current user in local storage.");
            setIsAuthenticated(false);
            return;
        }
    
        user.getSession((err, session) => {
            if (err) {
                console.error("Error getting session:", err);
                setIsAuthenticated(false);
            } else if (!session.isValid()) {
                console.error("Session is invalid. Trying to refresh...");
                user.refreshSession(session.getRefreshToken(), (refreshError, newSession) => {
                    if (refreshError) {
                        console.error("Error refreshing session:", refreshError);
                        setIsAuthenticated(false);
                    } else {
                        console.log("Session refreshed successfully.");
                        updateCurrentUser(newSession);
                    }
                });
            } else {
                updateCurrentUser(session);
            }
        });
    };
    
    const updateCurrentUser = (session) => {
        setCurrentUser(session.getIdToken().getJwtToken());
        setIsAuthenticated(true);
        console.log("Session is valid, tokens refreshed if necessary.");
    };
    
    useEffect(() => {
        checkSession();
        const interval = setInterval(checkSession, 5 * 60 * 1000); // Check every 5 minutes
    
        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, []);
    



    const refreshTokens = (user) => {
        user.refreshSession(user.getRefreshToken(), (err, session) => {
            if (err) {
                console.error("Error refreshing tokens:", err);
                setIsAuthenticated(false);
            } else {
                updateCurrentUser(session);
            }
        });
    };

    const signIn = async (username, password) => {
        const user = new CognitoUser({ Username: username, Pool: userPool });
        const authDetails = new AuthenticationDetails({ Username: username, Password: password });

        return new Promise((resolve, reject) => {
            user.authenticateUser(authDetails, {
                onSuccess: (result) => {
                    setCurrentUser(result.getIdToken().getJwtToken());
                    setIsAuthenticated(true);
                    resolve(result);
                },
                onFailure: (err) => {
                    setIsAuthenticated(false);
                    reject(err);
                }
            });
        });
    };

    const signOut = () => {
        const user = userPool.getCurrentUser();
        if (user) {
            user.signOut();
            setCurrentUser(null);
            setIsAuthenticated(false);
        }
    };

    return (
        <AuthContext.Provider value={{ currentUser, isAuthenticated, signIn, signOut, user, setUser  }}>
            {children}
        </AuthContext.Provider>
    );
};
