import { invokeLambda } from '../services/lambdaService'; // Make sure the path is correct based on your project structure
import { getIdentityId } from "../services/cognitoService";
import awsConfig from "../config/awsConfig";

  export const scanPageLambda = async ({ s3_image_key }) => {
  const identity_id = await getIdentityId(); // Assuming getIdentityId is a function that retrieves the current user's identity ID
  const payload = {
    body: JSON.stringify({
      s3_image_bucket: awsConfig.bucketName,
      s3_image_key: s3_image_key,
      identity_id: identity_id
    })
  };
  console.log("Payloadddddd", payload)

  try {
    const result = await invokeLambda({
      functionName: "arn:aws:lambda:eu-west-2:402162340420:function:HelloLambda", // Replace with your actual Lambda function name
      payload: payload
    });
    return result;
  } catch (error) {
    console.error('Error invoking Lambda function:', error);
    throw error;
  }
};
