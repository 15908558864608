import awsConfig from '../config/awsConfig';
import { DynamoDBClient, GetItemCommand, PutItemCommand, QueryCommand, DeleteItemCommand, UpdateItemCommand } from "@aws-sdk/client-dynamodb";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { getIdentityId } from "./cognitoService";
import { unmarshall } from "@aws-sdk/util-dynamodb";
import { getSessionToken } from '../services/cognitoService';



// Create a DynamoDB Client
const createDynamoDBClient = async () => {
  const token = await getSessionToken();
  return new DynamoDBClient({
    region: awsConfig.region,
    credentials: fromCognitoIdentityPool({
      clientConfig: { region: awsConfig.region },
      identityPoolId: awsConfig.identityPoolId,
      logins: {
        [`cognito-idp.${awsConfig.region}.amazonaws.com/${awsConfig.userPoolId}`]: token,
      },
    }),
  });
};

// Retrieve user data from DynamoDB
const getUserData = async () => {
  const identityId = await getIdentityId();
  const dynamoDbClient = await createDynamoDBClient();
  const params = {
    TableName: "UserData",
    Key: {
      "UserID": { S: identityId }
    }
  };

  try {
    const { Item } = await dynamoDbClient.send(new GetItemCommand(params));
    if (Item) {
      const userData = unmarshall(Item);
      return userData;
    } else {
      console.log("No data found for the user.");
      return {};
    }
  } catch (error) {
    console.error('Error fetching user data from DynamoDB:', error);
    throw new Error(`Error fetching user data: ${error}`);
  }
};

// Update user data in DynamoDB with optional parameters
const updateUserData = async ({ credits, translationLanguage, offlineMode, termsVersion }) => {
  const identityId = await getIdentityId();
  const dynamoDbClient = await createDynamoDBClient();

  let updateExpression = "SET";
  let expressionAttributeNames = {};
  let expressionAttributeValues = {};

  if (credits !== undefined) {
    updateExpression += " #credits = :credits,";
    expressionAttributeNames["#credits"] = "Credits";
    expressionAttributeValues[":credits"] = { N: String(credits) };
  }
  if (translationLanguage !== undefined) {
    updateExpression += " #translationLanguage = :translationLanguage,";
    expressionAttributeNames["#translationLanguage"] = "TranslationLanguage";
    expressionAttributeValues[":translationLanguage"] = { S: translationLanguage };
  }
  if (offlineMode !== undefined) {
    updateExpression += " #offlineMode = :offlineMode,";
    expressionAttributeNames["#offlineMode"] = "OfflineMode";
    expressionAttributeValues[":offlineMode"] = { BOOL: offlineMode };
  }
  if (termsVersion !== undefined) {
    updateExpression += " #termsVersion = :termsVersion,";
    expressionAttributeNames["#termsVersion"] = "TermsVersion";
    expressionAttributeValues[":termsVersion"] = { S: termsVersion };
  }

  // Remove trailing comma
  if (updateExpression.endsWith(',')) {
    updateExpression = updateExpression.slice(0, -1);
  }

  // Ensure there is something to update
  if (Object.keys(expressionAttributeNames).length === 0) {
    throw new Error("No attributes provided for update.");
  }

  const params = {
    TableName: "UserData",
    Key: {
      "UserID": { S: identityId }
    },
    UpdateExpression: updateExpression,
    ExpressionAttributeNames: expressionAttributeNames,
    ExpressionAttributeValues: expressionAttributeValues
  };

  try {
    await dynamoDbClient.send(new UpdateItemCommand(params));
    console.log("User data updated successfully.");
  } catch (error) {
    console.error('Error updating user data in DynamoDB:', error);
    throw new Error(`Error updating user data: ${error}`);
  }
};




// Retrieve all user folders
const getAllUserFolders = async () => {
  const identityId = await getIdentityId();
  const dynamoDbClient = await createDynamoDBClient();

  const params = {
    TableName: "UserFiles",
    KeyConditionExpression: "UserID = :userId",
    FilterExpression: "IsFolder = :isFolder",
    ExpressionAttributeValues: {
      ":userId": { S: identityId },
      ":isFolder": { S: "true" }
    }
  };

  try {
    const { Items } = await dynamoDbClient.send(new QueryCommand(params));

    const folders = Items.map(item => unmarshall(item));
    return folders;
  } catch (error) {
    console.error('Error fetching folders:', error);
    throw new Error(`Error fetching folders: ${error.message || error}`);
  }
};

// Retrieve all files in a specific folder
const getFilesInFolder = async (folderName) => {
  const identityId = await getIdentityId();
  const dynamoDbClient = await createDynamoDBClient();

  const params = {
    TableName: "UserFiles",
    KeyConditionExpression: "UserID = :userId",
    FilterExpression: "FolderName = :folderName and IsFolder = :isFolder",
    ExpressionAttributeValues: {
      ":userId": { S: identityId },
      ":folderName": { S: folderName },
      ":isFolder": { S: "false" }
    }
  };

  try {
    const { Items } = await dynamoDbClient.send(new QueryCommand(params));
    const files = Items.map(item => unmarshall(item));
    return files;
  } catch (error) {
    console.error('Error fetching files in folder:', error);
    throw new Error(`Error fetching files in folder: ${error.message || error}`);
  }
};

const getFilesInFolderPaginated = async (folderName, startKey = null, limit = 10) => {
  const identityId = await getIdentityId();
  const dynamoDbClient = await createDynamoDBClient();

  const params = {
    TableName: "UserFiles",
    KeyConditionExpression: "UserID = :userId and begins_with(ItemPath, :folderName)",
    FilterExpression: "IsFolder = :isFolder",
    ExpressionAttributeValues: {
      ":userId": { S: identityId },
      ":isFolder": { S: "false" },
      ":folderName": { S: `${folderName}/` }
    },
    Limit: limit,
    ExclusiveStartKey: startKey,
    ScanIndexForward: true
  };

  try {
    const response = await dynamoDbClient.send(new QueryCommand(params));
    const files = response.Items.map(item => unmarshall(item));
    console.log("Files in folder retrieved:", files);

    return {
      files,
      nextKey: response.LastEvaluatedKey
    };
  } catch (error) {
    console.error('Error fetching files in folder:', error);
    throw new Error(`Error fetching files in folder: ${error.message || error}`);
  }
};



// Add a new folder
// Add a new folder
const addNewFolder = async (folderName, coverImage) => {
  const identityId = await getIdentityId();
  const dynamoDbClient = await createDynamoDBClient();

  const params = {
    TableName: "UserFiles",
    Item: {
      "UserID": { S: identityId },
      "ItemPath": { S: folderName },
      "FolderName": { S: folderName },
      "IsFolder": { S: "true" },  // Change here from BOOL to S (string)
      "CreatedOn": { S: new Date().toISOString() },
      ...(coverImage && { "CoverImage": { S: coverImage } })
    }
  };

  try {
    await dynamoDbClient.send(new PutItemCommand(params));
    console.log("Folder created successfully.");
  } catch (error) {
    console.error('Error creating new folder:', error);
    throw new Error(`Error creating new folder: ${error.message || error}`);
  }
};


// Add files to a folder
const addFilesToFolder = async (folderName, files) => {
  const identityId = await getIdentityId();
  const dynamoDbClient = await createDynamoDBClient();

  for (let file of files) {
    const params = {
      TableName: "UserFiles",
      Item: {
        "UserID": { S: identityId },
        "FolderName": { S: folderName },
        "ItemPath": { S: `${folderName}/${file.name}` },
        "IsFolder": { S: "false" },
        "CreatedOn": { S: new Date().toISOString() },
        // "DataURL" will be a field to hold the url of the data.
      }
    };

    try {
      await dynamoDbClient.send(new PutItemCommand(params));
      console.log(`File ${file.name} added successfully.`);
    } catch (error) {
      console.error(`Error adding file ${file.name}:`, error);
      throw new Error(`Error adding file ${file.name}: ${error.message || error}`);
    }
  }
};

// Add a new folder and files to it
const addFolderAndFiles = async (folderName, files) => {
  // Determine the cover image, which is the first file in the list
  const coverImage = files.length > 0 ? `${folderName}/${files[0].name}` : null;

  // Create the folder with the cover image
  await addNewFolder(folderName, coverImage);

  // Add the files to the folder
  await addFilesToFolder(folderName, files);
};

// Delete a folder and its contents
const deleteFolderAndContents = async (folderName) => {
  const identityId = await getIdentityId();
  const dynamoDbClient = await createDynamoDBClient();

  // Get all files in the folder
  const files = await getFilesInFolder(folderName);

  for (let file of files) {
    const params = {
      TableName: "UserFiles",
      Key: {
        "UserID": { S: identityId },
        "ItemPath": { S: file.ItemPath }
      }
    };
    console.log("fileparams", params)

    try {
      await dynamoDbClient.send(new DeleteItemCommand(params));
      console.log(`File ${file.ItemPath} deleted successfully.`);
    } catch (error) {
      console.error(`Error deleting file ${file.ItemPath}:`, error);
      throw new Error(`Error deleting file ${file.ItemPath}: ${error.message || error}`);
    }
  }

  // Delete the folder itself
  const folderParams = {
    TableName: "UserFiles",
    Key: {
      "UserID": { S: identityId },
      "ItemPath": { S: folderName }    }
  };
  console.log("folderParams", folderParams)

  try {
    await dynamoDbClient.send(new DeleteItemCommand(folderParams));
    console.log("Folder deleted successfully.");
  } catch (error) {
    console.error('Error deleting folder:', error);
    throw new Error(`Error deleting folder: ${error.message || error}`);
  }
};




export { getUserData, updateUserData, getAllUserFolders, getFilesInFolder, getFilesInFolderPaginated,  addNewFolder, addFilesToFolder, addFolderAndFiles, deleteFolderAndContents };