import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSessionToken } from '../services/cognitoService';
import {
  addFolderAndFiles,
  getAllUserFolders,
  deleteFolderAndContents,
  addFilesToFolder,
} from '../services/dynamoDBService';
import FolderCard from './../components/FolderCard/FolderCard';
import AddFolderCard from './../components/AddFolderCard/AddFolderCard';
import EditFolderModal from './../components/EditFolderModal/EditFolderModal';
import YesNoMessageModal from '../components/YesNoMessageModal/YesNoMessageModal';
import AddFolderModal from '../components/AddFolderModal/AddFolderModal';
import { createS3Items, deleteS3Items } from './../services/s3Service';
import styles from './FolderManager.module.scss'; // Import styles as 'styles'

const FolderManager = () => {
  const [folders, setFolders] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [folderToEdit, setFolderToEdit] = useState(null);
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const [foldersAdding, setFoldersAdding] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchS3Items = async () => {
      const token = await getSessionToken();
      if (!token) {
        console.error('Token is not available in localStorage');
        return;
      }

      try {
        const folders = await getAllUserFolders();
        setFolders(folders);
      } catch (error) {
        console.error('Error fetching folders:', error);
      }
    };

    fetchS3Items();
  }, [refreshTrigger]);

  const handleDeleteButton = (folderName) => {
    setCurrentFolder(folderName);
    setIsModalOpen(true);
  };

  const handleDelete = async () => {
    if (currentFolder) {
      await deleteS3Items(currentFolder);
      await deleteFolderAndContents(currentFolder);
      setIsModalOpen(false);
      setFolders(folders.filter((folder) => folder.FolderName !== currentFolder));
    }
  };

  const handleAddFolder = async (folderName, files) => {
    setFoldersAdding([...foldersAdding, folderName]);
    await createS3Items(folderName, files);
    await addFolderAndFiles(folderName, files);
    setIsAddModalOpen(false);
    setRefreshTrigger(!refreshTrigger);
  };

  const handleEditButton = (folderName) => {
    setFolderToEdit(folderName);
    setIsEditModalOpen(true);
  };

  const handleAddFilesToFolder = async (folderName, files) => {
    try {
      await createS3Items(folderName, files);
      await addFilesToFolder(folderName, files);
      setIsEditModalOpen(false);
      setRefreshTrigger(!refreshTrigger);
    } catch (error) {
      console.error('Error adding files to folder:', error);
    }
  };

  return (
    <div className={styles['folderManager']}>
      <div className={styles['folderGrid']}>
        <AddFolderCard onClick={() => setIsAddModalOpen(true)} />
        {folders.map((folder, index) => (
          <FolderCard
            key={index}
            folder={folder}
            isLoading={false}
            onDelete={handleDeleteButton}
            onEdit={handleEditButton}
          />
        ))}
        {foldersAdding.map((name, index) => (
          <FolderCard key={index} folder={{ FolderName: name }} isLoading={true} />
        ))}
      </div>

      <YesNoMessageModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleDelete}
        message="Are you sure you want to delete this folder?"
      />
      <AddFolderModal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onAddFolder={handleAddFolder}
      />
      <EditFolderModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        folderName={folderToEdit}
        onAddFiles={handleAddFilesToFolder}
      />
    </div>
  );
};

export default FolderManager;
