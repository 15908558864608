import { openDB } from 'idb';
import { DATA_VERSION } from '../constants';

const DB_NAME = 'KanjiDatabase';
const DB_VERSION = 1;
const STORE_NAME = 'kanji';

export async function initDB() {
  return openDB(DB_NAME, DB_VERSION, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        const store = db.createObjectStore(STORE_NAME, { keyPath: 'literal' });
        store.createIndex('meanings', 'meanings', { multiEntry: true });
        store.createIndex('readings', 'readings.reading', { multiEntry: true });
      }
    },
  });
}

export async function loadKanjiData(db, data) {
  const tx = db.transaction(STORE_NAME, 'readwrite');
  const store = tx.objectStore(STORE_NAME);

  for (const kanji of data) {
    await store.put(kanji);
  }

  await tx.done;
}

export async function getKanjiByLiteral(db, literal) {
  return db.get(STORE_NAME, literal);
}

export async function checkAndLoadData() {
  const db = await initDB();
  const storedVersion = localStorage.getItem('kanjiDataVersion');

  if (storedVersion !== DATA_VERSION) {
    // Fetch the data and load it into IndexedDB
    const response = await fetch('/kanji_data.json');
    console.log("Reponse data", response)
    const kanjiData = await response.json();

    await loadKanjiData(db, kanjiData);

    localStorage.setItem('kanjiDataVersion', DATA_VERSION);
    console.log('Kanji data loaded into IndexedDB.');
  } else {
    console.log('Kanji data already up-to-date in IndexedDB.');
  }
}

export async function deleteDatabase() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.deleteDatabase(DB_NAME);
    request.onsuccess = () => {
      console.log('Old KanjiDatabase deleted successfully.');
      resolve();
    };
    request.onerror = () => {
      console.error('Error deleting old KanjiDatabase.');
      reject();
    };
  });
}
